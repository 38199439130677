// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Assets/images/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.BreakdownBodyContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  overflow: visible;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 13px;
  color: #f3f3f3;
  font-family: Cardo;
}

.BreakdownPageContainer {
  display: "flex";
  flex: 1px 1;
  background-color: #f3f3f3;
}




@media only screen and (max-width:499px) {
  
  .foot{
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Complete/components/BreakdownSection.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,qBAAqB;EACrB,iBAAiB;EACjB,yDAAsD;EACtD,sBAAsB;EACtB,4BAA4B;EAC5B,wBAAwB;EACxB,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAS;EACT,yBAAyB;AAC3B;;;;;AAKA;;EAEE;IACE,WAAW;EACb;AACF","sourcesContent":["\n.BreakdownBodyContainer {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  align-content: center;\n  overflow: visible;\n  background-image: url(\"../../../Assets/images/bg.png\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: top;\n  text-align: center;\n  font-size: 13px;\n  color: #f3f3f3;\n  font-family: Cardo;\n}\n\n.BreakdownPageContainer {\n  display: \"flex\";\n  flex: 1px;\n  background-color: #f3f3f3;\n}\n\n\n\n\n@media only screen and (max-width:499px) {\n  \n  .foot{\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
