// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.image-container {
  opacity: 0.5;
  background: #BBD2F5;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Complete/components/SectionalCards.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":["\n.image-container {\n  opacity: 0.5;\n  background: #BBD2F5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
