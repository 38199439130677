// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.summary-card {
    display: block;
}

@media only screen and (max-width: 430px) {
    .summary-card {
        display: none !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Complete/components/SummaryCard.css"],"names":[],"mappings":";AACA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":["\n.summary-card {\n    display: block;\n}\n\n@media only screen and (max-width: 430px) {\n    .summary-card {\n        display: none !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
