import background from "./bg.png";
import NFO from "./nfo.svg";
import NFOLogo from "./NFOLogo.svg";
import NFOLogo2x from "./nfologo@2x.png";
import NFOLogoBig from "./NFOLogoBig.svg";
import GuidebookPreview1 from "./advn.png";
import GuidebookPreview2 from "./Guide-Img.png";
import WorkbookPreview3 from "./Workbook-img.png";
import PhoneIcon from "./phone-icon.png";
import TitleLine from "./titleline.svg";
import comp from "./comp.svg";
import bullets from "./bullets.svg";
import whiteBullet from "./whiteBullet.svg";
import greenBullet from "./greenBullet.svg";
import orangeBullet from "./orangeBullet.svg";
import lightGreenBullet from "./lightGreenBullet.svg";
import bgup from "./bgup.svg";
import alert from "./alert.svg";
import Ellipse from "./Ellipse 17.svg";
import QPs3_Olympiad from "./QPs3_and_freeOlympiad.png";
import QPs3_afterFreeMock from "./QPs3_afterFreeMock.svg"
import x_circle from "./x-circle.svg";
import questionMark from "./questionMark.svg";
import questionMark2 from "./questionMark2.svg";
import AlertIcon from "./AlertIcon.png";
import RightArrowSVG from "./rightArrowSVG.svg";
import BankingLogo from "./BankingLogo.svg";
import TaxPlanningLogo from "./TaxPlanningLogo.svg";
import MoneyLogo from "./MoneyLogo.svg";
import FinancialPlanningLogo from "./FinancialPlanningLogo.svg";
import InvestmentLogo from "./InvestmentLogo.svg";
import LoansAndCreditLogo from "./LoansAndCreditLogo.svg";
import PurchaseQPs from "./purchaseQPs.svg";
import whiteRightArrow from "./whiteRightArrow.svg";
import leftScroll from "./left_scroll.svg";
import rightScroll from "./right_scroll.svg";

export {
  background,
  NFO,
  NFOLogo,
  NFOLogo2x,
  NFOLogoBig,
  GuidebookPreview1,
  GuidebookPreview2,
  WorkbookPreview3,
  PhoneIcon,
  TitleLine,
  comp,
  bullets,
  whiteBullet,
  greenBullet,
  orangeBullet,
  lightGreenBullet,
  bgup,
  alert,
  Ellipse,
  QPs3_Olympiad,
  QPs3_afterFreeMock,
  x_circle,
  questionMark,
  questionMark2,
  AlertIcon,
  RightArrowSVG,
  BankingLogo,
  TaxPlanningLogo,
  FinancialPlanningLogo,
  InvestmentLogo,
  MoneyLogo,
  LoansAndCreditLogo,
  PurchaseQPs,
  whiteRightArrow,
  leftScroll,
  rightScroll
};
