// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/images/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pdf-container{
    width: 100%;
    height: 80vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  
  .logo{
    position: absolute;
    height: 100px;
    width: 100px;
    top: 100px;
    left: 100px;
  }
  
  .bg{
     z-Index: 0px;
          position: "absolute";
          height: 100%;
          width: 100%,
  }
  
  .header{
    display: flex;
    width: '100%';
    align-items: center;
    
    padding: 20px;
    justify-content: center;
  }

  
  .back{
    cursor: pointer;
    font-family: Cardo;
    opacity: 90%;
    font-size:x-large;
    color: #2C6AB1;
  }
  
  
  .container{
    margin-top: 30px;
    border:  solid transparent;
    border-radius: 20px;
    background-color: #F3F3F3;
    box-shadow: 0 0 0 30px white;
    
  }
  .body{
    background-color: #F3F3F3;
    margin: auto;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: 100vh;
  }
  
  form{
    margin-top: 20px;
    text-align: center;
  }
  
  .btn{
    margin-top: 10px;
  }
  
  @media print{
    .body{
      display: none;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Pages/PdfPreview/PdfPreview.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;EACxB;;;EAGA;IACE,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,UAAU;IACV,WAAW;EACb;;EAEA;KACG,YAAY;UACP,oBAAoB;UACpB,YAAY;UACZ;EACR;;EAEA;IACE,aAAa;IACb,aAAa;IACb,mBAAmB;;IAEnB,aAAa;IACb,uBAAuB;EACzB;;;EAGA;IACE,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,cAAc;EAChB;;;EAGA;IACE,gBAAgB;IAChB,0BAA0B;IAC1B,mBAAmB;IACnB,yBAAyB;IACzB,4BAA4B;;EAE9B;EACA;IACE,yBAAyB;IACzB,YAAY;IACZ,yDAAiD;IACjD,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE;MACE,aAAa;IACf;EACF","sourcesContent":["\n.pdf-container{\n    width: 100%;\n    height: 80vh;\n    overflow-y: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n  }\n  \n  \n  .logo{\n    position: absolute;\n    height: 100px;\n    width: 100px;\n    top: 100px;\n    left: 100px;\n  }\n  \n  .bg{\n     z-Index: 0px;\n          position: \"absolute\";\n          height: 100%;\n          width: 100%,\n  }\n  \n  .header{\n    display: flex;\n    width: '100%';\n    align-items: center;\n    \n    padding: 20px;\n    justify-content: center;\n  }\n\n  \n  .back{\n    cursor: pointer;\n    font-family: Cardo;\n    opacity: 90%;\n    font-size:x-large;\n    color: #2C6AB1;\n  }\n  \n  \n  .container{\n    margin-top: 30px;\n    border:  solid transparent;\n    border-radius: 20px;\n    background-color: #F3F3F3;\n    box-shadow: 0 0 0 30px white;\n    \n  }\n  .body{\n    background-color: #F3F3F3;\n    margin: auto;\n    background-image: url(../../Assets/images/bg.png);\n    height: 100vh;\n  }\n  \n  form{\n    margin-top: 20px;\n    text-align: center;\n  }\n  \n  .btn{\n    margin-top: 10px;\n  }\n  \n  @media print{\n    .body{\n      display: none;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
